<template>
  <div>
    <component :is="FastLoading" />
    <div v-show="!$store.state.fastCarregando" class="fdn-home">
      <component :is="LandingPageRedirectContent" />
      <component :is="HomeFooter" />
    </div>
  </div>
</template>

<script>
import { settings } from "@/config";

export default {
  name: "LandingPageRedirect",
  data: function () {
    return {
      paginaAtual: this.$route.name,
      fastTemplate: settings.fastTemplate,
      fastComponents: settings.fastComponents,
    };
  },
  computed: {
    LandingPageRedirectContent() {
      return () =>
        this.fastComponents.LandingPageRedirectContent
          ? import(
              `@/templates/${this.fastComponents.LandingPageRedirectContent}`
            )
          : import(`@/templates/${this.fastTemplate}/${this.paginaAtual}.vue`);
    },
    HomeFooter() {
      return () =>
        this.fastComponents.HomeExternoFooter
          ? import(`@/templates/${this.fastComponents.HomeExternoFooter}`)
          : import(`@/templates/${this.fastTemplate}/HomeExternoFooter.vue`);
    },
    FastLoading() {
      return () =>
        import(`@/templates/${this.fastTemplate}/components/Loading.vue`);
    },
  },
  mounted() {
    //console.log(this.fastComponents.CadastroAlunoExternoContent);
  },
  created() {},
  methods: {},
};
</script>

<style>
.fdn-home {
  min-height: 100vh;
}
</style>
